import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap';
import VueResource from 'vue-resource';
import VueAnalytics from 'vue-analytics';
import VueCookies from 'vue-cookies';



import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faXing, faLinkedin, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faLocationArrow, faPhoneVolume, faServer, faObjectGroup, faBookmark, faChevronDown, faChevronUp, faChevronRight, faBusinessTime, faProjectDiagram, faLaptopCode, faHandshake, faCompass, faMailBulk, faAdjust, faNetworkWired, faIndustry, faChevronLeft, faWifi, faBoxes, faBox, faShieldAlt, faCalendarAlt  } from '@fortawesome/free-solid-svg-icons';

import VueScrollTo from "vue-scrollto";
import AOS from 'aos';
import 'aos/dist/aos.css';



Vue.use(VueScrollTo);
Vue.use(VueResource);
Vue.use(VueAnalytics, {
  id: 'UA-117084477-2',
  checkDuplicatedScript: true,
  router,
  autoTracking: {
    screenview: true
  },
  disabled: true
});
Vue.use(VueCookies);

library.add(faPhone, faLocationArrow, faPhoneVolume, faServer, faObjectGroup, faBookmark, faChevronDown, faChevronUp, faChevronRight, faCompass, faBusinessTime, faProjectDiagram, faLaptopCode, faHandshake, faMailBulk, faAdjust, faNetworkWired, faIndustry, faChevronLeft, faWifi, faBoxes, faBox, faShieldAlt, faCalendarAlt);
library.add(faXing, faLinkedin, faFacebook, faYoutube);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount("#app");
