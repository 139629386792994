import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Career from "../views/Career.vue";
import Contact from "../views/Contact.vue";
import Clients from "../views/Clients.vue";
import News from "../views/News.vue";
import Impressum from "../views/Impressum.vue";
import Privacy from "../views/Privacy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: 'Businessconsult24 - Home'
    }
  },
  {
    path: "/karriere",
    name: "career",
    component: Career,
    meta: {
      title: 'Businessconsult24 - Karriere'
    }
  },
  {
    path: "/kontakt/:topic/:subtopic",
    name: "contact",
    component: Contact,
    meta: {
      title: 'Businessconsult24 - Kontakt'
    }
  },
  {
    path: "/kontakt",
    name: "contact",
    component: Contact,
    meta: {
      title: 'Businessconsult24 - Kontakt'
    }
  },
  {
    path: "/partner",
    name: "clients",
    component: Clients,
    meta: {
      title: 'Businessconsult24 - Partner'
    }
  },
  {
    path: "/news",
    name: "news",
    component: News,
    meta: {
      title: 'Businessconsult24 - News'
    }
  },
  {
    path: "/impressum",
    name: "impressum",
    component: Impressum,
    meta: {
      title: 'Businessconsult24 - Impressum'
    }
  },
  {
    path: "/datenschutz",
    name: "privacy",
    component: Privacy,
    meta: {
      title: 'Businessconsult24 - Datenschutz'
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
});

export default router;
